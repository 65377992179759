
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import _model from '@/static/model/orderbill/code'
export default {
	components: { },
	setup(props, context) {
		return {
				_model: _model,
				options: reactive({}),
				setting: reactive({
				module: 'Product',
				moduleName: '商品订单',
				searchApi: '/Order/Order/OrderList',
				addVisible: false,
				editVisible: false,
				openAdd: false,
				openEdit: false,
				openManage: true,
				openDelete: false,
				openQuick: true,
				openDeleteBatch: false,
				tableFirstType: 'selection',
			}),
			list: reactive({
				data: [ ]
			}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
	}
}

